import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';

import {Box, Dialog, Typography} from '@material-ui/core';
import {CalendarToday, Room} from '@material-ui/icons';
import Button from "./Button";
import {editServices, fetchAlternativeServicesByAlternativeLabel, getParams, updatePreview} from "../utils/api";
import {useHistory} from "react-router-dom";
import {formatPrice} from "../utils/commonFunctions";
import {SuccessAlert, WarningAlert, WarningAlertBigger} from "../utils/commonStyledComponents";
import {PRACTICE_TYPES} from "./PracticeItem";
import {PRACTICE_TYPE_INPS, PRACTICE_TYPE_INPSNUOVO} from "../utils/constants";
import PaymentButton from "./Payments/PaymentButton";

const PackageDetails = props => {
    const history = useHistory();
    const [editingServices, setEditingServices] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    //Se questa variabile è vera, vuol dire che è stato modificato qualcosa e quindi non mostro i pulsanti di modifica pratica e modifica servizio
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [onDialogNext, setOnDialogNext] = useState(null);
    const [serviceList, setServiceList] = useState(props.list);
    const [alternativeServicesGroup, setAlternativeServicesGroup] = useState([]);
    const [alternativeServicesItem, setAlternativeServicesItem] = useState([]);
    const [selectedAlternatives, setSelectedAlternatives] = useState({});
    const [surcharge, setSurcharge] = useState(null);
    const [partoSicuroFlag, setPartoSicuroFlag] = useState(false);


    const fetchPartoSicuroFlag = async () => {
        try {
            const response = await getParams(props.productDivisionId, ["areaClub.domandaPartoSicuro"]);
            if (response['areaClub.domandaPartoSicuro']) {
                setPartoSicuroFlag(response['areaClub.domandaPartoSicuro'].status);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetchPartoSicuroFlag();
    }, []);

    const fetchPreview = async () => {
        try {
            const payload = {
                "practiceId": props.practiceId,
                "newPackageId": props.id,
                "newPackageItems": Object.values(selectedAlternatives).map((x) => x.value)
            }
            const preview = await updatePreview(payload);
            if (preview?.payload?.modificationSurcharge ?? false) {
                console.info("preview?.payload?.modificationSurcharge", preview?.payload?.modificationSurcharge);
                setSurcharge(preview?.payload?.modificationSurcharge);
            } else {
                setSurcharge(null);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const clear = () => {
        setEditingServices(false);
        setAlternativeServicesGroup([]);
        setAlternativeServicesItem([]);
        setSelectedAlternatives({});
        setOnDialogNext(null);
        setSurcharge(null);
        setShowDialog(false);
    }

    const renderSurcharge = () => surcharge &&
        <WarningAlert>Non avendo acquistato il servizio di modifica pratica in fase di prenotazione, ricordiamo che per
            salvare la modifica verrà applicato il supplemento di {formatPrice(surcharge)}</WarningAlert>;

    const renderMainEditServicesButtons = () => <>{((serviceList.length > 0) && isServiceEditable) && !editingServices && !showSuccessAlert &&
        <OutlinedInlineButton
            text="Modifica servizi"
            onClick={setChildrenEditable}
        />
    }
        {((serviceList.length > 0) && isServiceEditable) && editingServices &&
            <>
                <ContainedInlineButton
                    text="Salva modifica"
                    onClick={() => {
                        setDialogContent("Ti ricordiamo che confermando questa richiesta <strong>i servizi precedentemente selezionati verranno annullati e sostituiti con quelli appena scelti</strong>.Il valore totale della prenotazione potrebbe essere rimodulato in base alle scelte effettuate.");
                        setShowDialog(true);
                        setOnDialogNext(() => onUpdate);
                    }
                    }
                />
                <OutlinedInlineButton
                    text="Annulla modifica"
                    onClick={setChildrenEditable}
                />
            </>
        }</>

    const onUpdate = async () => {
        setShowDialog(false);
        const payloadToSend = {
            practiceId: props.practiceId,
            newPackageItems: Object.values(selectedAlternatives).map((x) => x.value)
        };
        try {
            const result = await editServices(payloadToSend);
            if (result) {
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                    window.location.reload();
                }, 4000);
            } else {
                console.error(result);
                alert("Qualcosa è andato storto durante la modifica");
            }
        } catch (e) {
            console.error("e", e);
            alert("Qualcosa è andato storto durante la modifica");
            throw e;
        } finally {
            clear();
        }
    }
    const setChildrenEditable = () => {
        if (editingServices) {
            clear();
        } else {
            setEditingServices(true);
        }
    }

    let isPracticeEditable = (props.editableInClubArea ?? false) && !showSuccessAlert;

    //Controllo che non ci sia alcun servizio con alternative label = 'assistenti'. Se lo trovo, la pratica non è modificabile
    const hasAlternativeLabelAssistenti = (serviceList.filter(x => x.alternativeLabel === 'assistenti').length > 0);
    if (hasAlternativeLabelAssistenti) {
        isPracticeEditable = false;
    }

    let isServiceEditable = isPracticeEditable;

    //Inoltre SOLO la pratica è modificabile solo e soltanto se appartiene alle divisioni prodotto 1 e 3, mentre i servizi rimangono editabili con la stessa condizione
    if (props.productDivisionId !== 1 && props.productDivisionId !== 3) {
        isPracticeEditable = false;
    }

    //Mostro il boxe parto sicuro se la pratica è di tipo inps, se è abilitato il flag generale "Parto sicuro" e se la pratica stessa non ha il flag parto sicuro
    const practiceTypeString = PRACTICE_TYPES[props.practiceType];
    const showPartoSicuroAlert = (practiceTypeString === PRACTICE_TYPE_INPS || practiceTypeString === PRACTICE_TYPE_INPSNUOVO) && partoSicuroFlag && !props.practice.partoSicuro;
    const showSuccessPartoSicuro = (practiceTypeString === PRACTICE_TYPE_INPS || practiceTypeString === PRACTICE_TYPE_INPSNUOVO) && partoSicuroFlag && props.practice.partoSicuro;

    const advancePaymentINPS = props.practice?.buyedPackage?.basicCore?.advancePaymentINPS ?? 10000;

    return (
        <>
            <Container>
                <DateBox>
                    Data di prenotazione:&nbsp;
                    <Text> {formatDate(new Date(props.date), 'dd/MM/yyyy')} </Text>
                </DateBox>
                <TitleBox>
                    {props.title}&nbsp;&nbsp;<Subtitle>[ID: {props.webidpackage}]</Subtitle>
                </TitleBox>
                <Text>Cod: {props.code}</Text>
                {
                    isPracticeEditable ?
                        <>
                            <OutlinedButton
                                text="Modifica prenotazione&nbsp;>"
                                onClick={() => {
                                    setDialogContent(`<u>Importante</u>: modificando la prenotazione<strong> i servizi precedentemente selezionati verranno annullati e sostituiti con quelli che andrai a selezionare.</strong> Il valore totale della prenotazione potrebbe essere rimodulato in base ai prezzi di vendita attuali.`);
                                    setShowDialog(true);
                                }}
                            />
                            <Text variant="body2">Potrai modificare: Destinazione - Pacchetto - Turno</Text>
                            <Text variant="body2"><strong>Nota</strong>: Se non hai acquistato la funzionalità di
                                modifica pratica durante la prenotazione, ti potrebbe essere richiesto un piccolo
                                supplemento</Text>
                        </>
                        : (hasAlternativeLabelAssistenti ?
                            <Bold style={{color: 'red'}} variant="body2">Solo per esigenze speciali disabilità: se hai
                                necessità di modificare la tua prenotazione invia la richiesta al seguente indirizzo
                                email: <a
                                    href="mailto:amministrazione2@giocamondo.it">amministrazione2@giocamondo.it</a> per
                                permetterci una migliore gestione della
                                prenotazione.</Bold> : '')
                }
                <IconBoxes>
                    <IconBox>
                        <CalendarToday/>
                        &nbsp;
                        <Text variant="caption">{` dal ${formatDate(
                            new Date(props.periodFrom),
                            'dd/MM/yyyy'
                        )} al ${formatDate(new Date(props.periodTo), 'dd/MM/yyyy')}`}</Text>
                    </IconBox>

                    <IconBox display="inline-flex" bgcolor="#f7f7f7;" alignItems="center">
                        <Room/>
                        &nbsp;
                        <Text variant="caption"> {props.destination}</Text>
                    </IconBox>

                    <Text style={{display: "inline"}} variant="h6"> Costo totale: € {props.practicePrice / 100}</Text>
                </IconBoxes>

                {/*Box Parto sicuro*/}
                {showPartoSicuroAlert && <>
                    <WarningAlertBigger>Vuoi bloccare il tuo posto e partire a prescindere dal bando
                        INPS? Seleziona l’opzione PARTO SICURO e paga la quota di {formatPrice(advancePaymentINPS)}.
                        <br/>
                        <PaymentButton
                            amount={advancePaymentINPS}
                            practice={props.practice}
                            hideBankTransfer={true}
                            onComplete={() => props.onCompletePayment(props.practice)}
                            displayAlert={props.displayAlert}
                            isPracticePayable={props.practice.readableStatus.trim().toLowerCase() === "opzionato" || props.practice.readableStatus.trim().toLowerCase() === "confermato"}
                            shortCode={props.practice.shortCode}
                        />
                    </WarningAlertBigger>
                </>
                }{showSuccessPartoSicuro && <>
                <SuccessAlert>Congratulazioni, sei un Parto Sicuro! Il posto al soggiorno da te prescelto è stato
                    bloccato
                </SuccessAlert>
            </>
            }


                {/*The list is editable if there are at least one item and some server-conditions are validated*/}
                {
                    <>
                        <Subtitle style={{display: "inline-flex"}}>Lista dei servizi acquistati</Subtitle>
                        {renderMainEditServicesButtons()}
                        {renderSurcharge()}
                        {showSuccessAlert && <SuccessAlert>Congratulazioni! La tua prenotazione è stata modificata con
                            successo</SuccessAlert>}
                        <Text variant="body2">
                            Potrai modificare: Fascia ISEE - Città di partenza - Modalità di pagamento
                        </Text>
                        <Text variant="body2"><strong>Nota</strong>: Se non hai acquistato la funzionalità di modifica
                            pratica durante la prenotazione, ti potrebbe essere richiesto un piccolo supplemento</Text>
                    </>
                }
                {
                    renderList(
                        serviceList,
                        editingServices,
                        (list) => setServiceList([...list]),
                        props.productDivisionId,
                        props.divisionId,
                        props.id, //packageId
                        alternativeServicesGroup,
                        (newList) => setAlternativeServicesGroup([...newList]),
                        alternativeServicesItem,
                        (newList) => setAlternativeServicesItem([...newList]),
                        selectedAlternatives,
                        (newObj) => setSelectedAlternatives({...newObj}),
                        () => fetchPreview(),
                    )
                }
                {renderSurcharge()}
                <FooterServices item xs={12}>
                    {renderMainEditServicesButtons()}
                </FooterServices>
            </Container>

            <Dialog open={showDialog} onBackdropClick={() => setShowDialog(false)}>
                <DialogContent>
                    <Text dangerouslySetInnerHTML={{__html: dialogContent}}/>
                    <Row>
                        <OutlinedButton
                            text="Procedi alla modifica"
                            onClick={onDialogNext ?? (() => history.push(`edit-practice/${props.practiceId}`))}
                        />
                        <ContainedButton
                            text="Annulla"
                            onClick={() => setShowDialog(false)}
                        />
                    </Row>
                </DialogContent>
            </Dialog>
        </>
    );
};

/**
 *
 * @param serviceList list of services bought by the user
 * @param isServicesEditable it shows the icon to edit services
 * @param setServiceList setter of the service list
 * @param productDivisionId used to make specific http call
 * @param divisionId used to make specific http call
 * @param packageId used to make specific http call
 * @param alternativeServicesGroup it contains the alternative services depending on group
 * @param setAlternativeServicesGroup setter of alternative services depending on group
 * @param alternativeServicesItem it contains the alternative services depending on item
 * @param setAlternativeServicesItem setter of alternative services depending on item
 * @param selectedAlternatives object that contains all user edits
 * @param setSelectedAlternatives setter of user edits obj
 * @param fetchPreview update preview
 * @returns {JSX.Element}
 */
const renderList = (serviceList, isServicesEditable, setServiceList, productDivisionId, divisionId, packageId, alternativeServicesGroup, setAlternativeServicesGroup, alternativeServicesItem, setAlternativeServicesItem, selectedAlternatives, setSelectedAlternatives, fetchPreview) => {
    if (!serviceList.length) {
        return <i>Nessun servizio acquistato</i>;
    }

    //Start handlers
    const onSelectGroupAlternativeClick = (group, alternativeService) => {
        selectedAlternatives[group.groupName] = alternativeService;
        setSelectedAlternatives(selectedAlternatives);
        fetchPreview();
    }
    const onSelectItemAlternativeClick = (item, alternativeService) => {
        selectedAlternatives[item.serviceId] = alternativeService;
        setSelectedAlternatives(selectedAlternatives);
        fetchPreview();
    }
    const onEditGroupClick = async group => {
        //Get alternative services depending on alternative label
        //Alternative labels are similar in the group items
        const alternativeLabel = group.items[0].alternativeLabel; //Here the item exists
        const result = await fetchAlternativeServicesByAlternativeLabel(alternativeLabel, productDivisionId, packageId, divisionId);
        if (!result) {
            console.error("error fetchAlternativeServicesByAlternativeLabel group", result);
            //error
            alert('Qualcosa è andato storto');
        }
        setAlternativeServicesGroup([...alternativeServicesGroup, {groupName: group.groupName, items: result}]);
    }
    const onEditItemClick = async item => {
        const alternativeLabel = item.alternativeLabel;
        const result = await fetchAlternativeServicesByAlternativeLabel(alternativeLabel, productDivisionId, packageId, divisionId);
        if (!result) {
            console.error("error fetchAlternativeServicesByAlternativeLabel item", result);
            //error
            alert('Qualcosa è andato storto');
        }
        const resultAfterExtraServicesCheck =
            ('val' in result && result.val !== null && 'extraservices' in result.val) ?
                result.val.extraservices :
                result
        ;
        setAlternativeServicesItem([...alternativeServicesItem, {
            serviceId: item.serviceId,
            items: resultAfterExtraServicesCheck
        }]);
    }
    const isItemEditable = item => isServicesEditable && (item.alternativeLabel != null) && (item.editableInClubArea ?? false);
    //End handlers


    /*
    item è così composto:
    {
      "editableInClubArea": false,
      "code": "PROMODESCRIZIONE",
      "name": "-----------------------------------------------------------------------------------------------------",
      "alternativeLabel": null,
      "itemGroup": null,
      "quantity": 1,
      "price": 0,
      "service": {
        "id": 3533
      }
    }
     */
    //Start component renders
    const renderItemName = (item, index, isGroupEditable) => {
        //The item is editable only if the group is not editable (it means that the group has just one item)
        const editable = (isItemEditable(item) && !isGroupEditable);
        let isItemEditing = false;

        const alternativeServices = alternativeServicesItem?.filter((x) => x.serviceId === item.serviceId);
        let currentAltSer;
        if (alternativeServices.length > 0) {
            isItemEditing = true;
            currentAltSer = alternativeServices[0];
        }
        return <ListTitle>
            {item.displayName}
            {
                editable && !isItemEditing ?
                    <EditItemButton
                        text="Modifica"
                        onClick={() => onEditItemClick(item)}
                    /> : ''
            }
            {renderAlternativeServicesItem(currentAltSer, item)}
        </ListTitle>;
    }

    const renderGroupTitle = (group, isGroupEditable, isGroupEditing) => (group.groupName !== "") &&
        <ItemGroupTitle>
            <Subtitle>{group.groupName}</Subtitle>
            {isGroupEditable && !isGroupEditing ?
                <EditButton
                    text="Modifica"
                    onClick={() => onEditGroupClick(group)}
                />
                : ''
            }
        </ItemGroupTitle>

    const renderAdditionalLabel = (label, additionalProps) => <small
        style={{alignSelf: 'center', ...additionalProps}}><strong>{label}</strong></small>;


    const renderAlternativeServicesItem = (currentAltSer, item) => currentAltSer && <SelectionBox>
        {(currentAltSer?.items?.length === 0) ? "Nessun servizio alternativo" : currentAltSer?.items?.map((altSer, idx) => {
            //It indicates if the user has selected the item in this group
            const isCurrentSelected = selectedAlternatives[item.serviceId] && selectedAlternatives[item.serviceId]?.label === altSer.label;

            //It indicates that the service is the original one
            const isOriginal = (altSer.serviceId == item.serviceId);

            const textStyle = {
                display: 'grid',
                color: isOriginal ? 'red' : 'black',
                fontWeight: isOriginal ? 'bold' : 'normal'
            };
            return (
                <ListRow key={altSer.label + idx}>
                    <ListBullet/>
                    <ListTitle style={textStyle}>{altSer.label}</ListTitle>
                    <ListDetail>
                        {
                            //If the item is the selected one, show "ATTUALE" label
                            //else if the user has already selected an item, show "SELEZIONATO" label
                            //else show "SELEZIONA" button to select it
                            (isCurrentSelected ?
                                    renderAdditionalLabel('NUOVA SELEZIONE', {textAlign: 'center', ...textStyle}) :
                                    isOriginal ?
                                        renderAdditionalLabel('ATTUALE SELEZIONE', {textAlign: 'center', ...textStyle}) :
                                        <EditButton
                                            text="Seleziona"
                                            onClick={() => onSelectItemAlternativeClick(item, altSer)}
                                        />
                            )
                        }
                    </ListDetail>
                    <ListDetail style={textStyle}>{formatPrice(altSer.price ?? (altSer.realvalue ?? -1))}</ListDetail>
                </ListRow>
            );
        })}
    </SelectionBox>

    const renderAlternativeServicesGroup = (currentAltSer, group) => currentAltSer &&
        <SelectionBox>
            {(currentAltSer?.items?.length === 0) ? "Nessun servizio alternativo" : currentAltSer?.items?.map((altSer, idx) => {
                //It indicates if the user has selected the item in this group
                const isCurrentSelected = selectedAlternatives[group.groupName] && selectedAlternatives[group.groupName]?.label === altSer.label;

                //It indicates that the service is the original one
                const isOriginal = (altSer.label === group.groupName);


                const textStyle = {
                    display: 'inline-flex',
                    color: isOriginal ? 'red' : 'black',
                    fontWeight: isOriginal ? 'bold' : 'normal',
                    textDecoration: altSer.soldout ? 'line-through' : 'none',
                };
                return (
                    <ListRow key={altSer.label + idx}>
                        <ListBullet/>
                        <ListTitle style={textStyle}>{altSer.label}</ListTitle>
                        <ListDetail>
                            {
                                //If the item is the selected one, show "ATTUALE" label
                                //else if the user has already selected an item, show "SELEZIONATO" label
                                //else show "SELEZIONA" button to select it
                                (isCurrentSelected ?
                                        renderAdditionalLabel('NUOVA SELEZIONE') :
                                        isOriginal ?
                                            renderAdditionalLabel('ATTUALE SELEZIONE') :
                                            (
                                                altSer.soldout ?
                                                    renderAdditionalLabel('SOLDOUT') :
                                                    <EditButton
                                                        text="Seleziona"
                                                        onClick={() => onSelectGroupAlternativeClick(group, altSer)}
                                                    />
                                            )
                                )
                            }
                        </ListDetail>
                        <ListDetail></ListDetail>
                    </ListRow>
                );
            })}
        </SelectionBox>

    const renderCurrentServices = (group, isGroupEditable) => group.items.map((item, index) => {
        item.displayName = `${item.code || ''} ${item.name || ''}`;
        item.serviceId = item.service.id;
        return (
            <ListRow key={index}>
                <ListBullet/>
                {renderItemName(item, index, isGroupEditable)}
                <ListDetail>{item.quantity}</ListDetail>
                <ListDetail>{formatPrice(item.price)}</ListDetail>
            </ListRow>
        );
    })
    //End component renders


    const groupedItems = serviceList.reduce((groups, item) => {
        const groupKey = item.itemGroup || "";
        groups[groupKey] = groups[groupKey] || [];
        groups[groupKey].push(item);
        return groups;
    }, {});

    const groupedList = Object.entries(groupedItems).map(([groupKey, items]) => ({
        groupName: groupKey,
        items
    }));

    return (
        <>
            <ListBox className="listbox">
                <ListTitle/>
                <ListDetail>qt.</ListDetail>
                <ListDetail>prezzo €</ListDetail>
            </ListBox>

            {groupedList.map((group, index) => {
                let isGroupEditable = false;
                let isGroupEditing = false;
                const alternativeServices = alternativeServicesGroup?.filter((x) => x.groupName === group.groupName);
                let currentAltSer;
                if (alternativeServices.length > 0) {
                    isGroupEditing = true;
                    currentAltSer = alternativeServices[0];
                }

                if ((group.items.length > 1) && (group.groupName !== "")) {
                    //Group is editable if at least one of its item is editable, the group contains at least 2 items and the name is not empty
                    const item = group.items[0];
                    isGroupEditable = isItemEditable(item);
                }
                return <>
                    {renderGroupTitle(group, isGroupEditable, isGroupEditing)}

                    {renderAlternativeServicesGroup(currentAltSer, group)}

                    {renderCurrentServices(group, isGroupEditable)}
                </>;
            })}

        </>
    );
};

PackageDetails.propTypes = {
    title: PropTypes.string,
    webidpackage: PropTypes.string,
    code: PropTypes.string,
    destination: PropTypes.string,
    date: PropTypes.string,
    periodFrom: PropTypes.string,
    periodTo: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string,
            name: PropTypes.string,
            quantity: PropTypes.number,
            price: PropTypes.number,
        })
    ),
};

export default PackageDetails;

const Container = styled.div`
  padding: 20px; /* 20231103 */
`;

const Text = styled(Typography).attrs({
    color: 'textPrimary',
    component: 'p',
})``;

const Subtitle = styled(Text)`
  font-size: 16px;
  font-weight: 700;
`;

const DateBox = styled(Box)`
  display: inline-flex;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 2px solid lightgrey;
  padding: 4px 0;
`;

const TitleBox = styled(Box)`
  display: flex;
  align-items: flex-end;
  font-weight: 800;
  font-size: 20px;
  margin-top: 12px;
`;

const IconBoxes = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`;

const IconBox = styled(Box)`
  display: inline-flex;
  background-color: #f7f7f7;
  align-items: center;
  width: 250px;
  margin-right: 8px;
  padding: 8px 12px;
  color: #3f51b4;
`;

const ItemGroupTitle = styled(Box)`
  display: inline-flex;
  background-color: #f7f7f7;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 8px 12px;
`;
const SelectionBox = styled.ul`
  list-style-type: none;
  background-color: #f7f7f7;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 8px 12px;
`;

const SelectionItem = styled.li`
  margin-bottom: 4px;
  margin-top: 4px;
`;

const ListBox = styled(Box)`
  padding: 0 8px;
  display: flex;
  max-width: 600px; /* 20231102 */
  color: grey;
  font-size: 10px;
`;

const ListRow = styled(ListBox)`
  padding: 12px 8px;
  border-bottom: 1px solid lightgrey;
  color: black;
  font-size: 14px;
`;

const ListBullet = styled.div`
  height: 10px;
  min-width: 10px;
  margin-top: 2px;
  border-radius: 50%;
  background-color: #f50057;
`;

const FooterServices = styled.div`
  margin-top: 5px;
`;

const ListTitle = styled(Box)`
  flex-grow: 1;
  padding: 0 32px 0 16px;
`;

const ListDetail = styled(Box)`
  min-width: 50px;
  display: inline-flex;
  justify-content: center;
`;

const SelectionLabel = styled.span`
  margin-right: 5px;
`;

const OutlinedButton = styled(Button).attrs({
    variant: 'outlined',
    color: 'primary',
})``;

const OutlinedInlineButton = styled(Button).attrs({
    variant: 'outlined',
    color: 'primary',
})`
  display: inline-flex !important;
  margin-left: 8px !important;
  padding: 0 15px;
`;

const ContainedButton = styled(Button).attrs({
    variant: 'contained',
    color: 'primary',
})`
  color: white;
`;

const ContainedInlineButton = styled(ContainedButton)`
  display: inline-flex !important;
  margin-left: 3px !important;
  color: white;
  padding: 0 15px;
`;

const EditButton = styled(ContainedButton)`
  margin: 0;
  margin-left: 5px;
  color: white;
  line-height: 1;
  padding: 5px 8px;
  align-self: center;
`;
const EditItemButton = styled(EditButton)`
  margin: 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 32px;
`;

const DialogContent = styled(Typography).attrs({component: 'div'})`
  padding: 32px;
`;

const Bold = styled(Text)`
  font-weight: 600;
`;
