import styled, {css} from 'styled-components';
import MuiAlert from '@material-ui/lab/Alert';
import {CircularProgress, Grid, Typography} from '@material-ui/core';

import Button from '../components/Button';

//pratices components
export const Container = styled.div`
  overflow-x: auto;
  padding: 32px;
  flex-grow: 1;
`;

const centerStyle = css`
  margin: auto;
  margin-top: 32px;
`;

export const Spinner = styled(CircularProgress).attrs({color: 'primary'})`
  display: block;
  ${centerStyle};
`;

export const ErrorAlert = styled(MuiAlert).attrs({severity: 'error'})`
  display: flex;
  max-width: 350px;
  ${centerStyle};
`;
export const InfoAlert = styled(MuiAlert).attrs({severity: 'info'})`
  display: flex;
  max-width: 350px;
  ${centerStyle};
`;

export const WarningAlert = styled(MuiAlert).attrs({severity: 'warning'})`
  display: flex;
  max-width: 350px;
  ${centerStyle};
`;

export const WarningAlertBigger = styled(MuiAlert).attrs({severity: 'warning'})`
  display: flex;
  max-width: 700px;
  ${centerStyle};
`;

export const SuccessAlert = styled(MuiAlert).attrs({severity: 'success'})`
  display: flex;
  max-width: 350px;
  ${centerStyle};
`;

export const EmptyText = styled(Typography).attrs({
    variant: 'h3',
    align: 'center',
    color: 'textSecondary',
})``;

//form components
export const FieldsContainer = styled(Grid).attrs({container: true})`
  display: block;
  margin: auto;
  padding: 32px;
  max-width: 800px;
`;

export const FormTitle = styled(Typography).attrs({
    variant: 'h1',
})`
  margin: 0 0 32px 8px;
`;

export const FormSectionTitle = styled(FormTitle).attrs({
    component: 'h3',
    variant: 'h3',
})`
  margin: 32px 0 0 8px;
`;

export const SubmitButton = styled(Button).attrs({
    type: 'submit',
    variant: 'contained',
})`
  display: block;
  margin: 32px auto;
`;

export const SecondarySubmitButton = styled(Button).attrs({
    type: 'submit',
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
})`
  max-width: 380px;
  display: block;
  margin: 32px auto 16px;
  color: ${props => props.theme.palette.common.white};
`;
